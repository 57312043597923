import EditButton from '@rsa-digital/evo-shared-components/components/EditButton';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { generateKeyForElement } from '@rsa-digital/evo-shared-components/helpers/elementKeyHelper';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import Declaration from 'components/Declaration';
import RichTextWithModal from 'components/RichTextWithModal';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import {
  AssumptionsAgreement,
  useAssumptionsAgreement,
} from 'state/formData/assumptionsAgreement';
import useAssumptions from './assumptions';
import {
  AggregatorList,
  AggregatorListItem,
  StyledSectionHeading,
  Subheading,
} from './styles';

type AggregatorAssumptionsSectionProps = {
  sectionHeadingId: string;
  getError: FieldFunction<AssumptionsAgreement, string | undefined>;
  showValidation: FieldFunction<AssumptionsAgreement, void>;
};

type CsAggregators = {
  csPetAggregators: {
    assumptions: {
      section_heading: string;
      subheading: string;
      confirmation_text: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAggregators {
      assumptions {
        section_heading
        subheading
        confirmation_text
        edit_button_text
        edit_button_screenreader_text
      }
    }
  }
`;

const AggregatorAssumptionsSection: React.FC<AggregatorAssumptionsSectionProps> = ({
  sectionHeadingId,
  getError,
  showValidation,
}) => {
  const {
    csPetAggregators: {
      assumptions: {
        section_heading,
        subheading,
        confirmation_text,
        edit_button_text,
        edit_button_screenreader_text,
      },
    },
  } = useStaticQuery<CsAggregators>(query);

  const assumptions = useAssumptions();
  const [assumptionsAgreement, updateAssumptionsAgreement] = useAssumptionsAgreement();

  /* istanbul ignore if */
  if (!assumptions) {
    return null;
  }

  return (
    <>
      <StyledSectionHeading heading={section_heading} id={sectionHeadingId} />
      <Grid alignLeft>
        <GridItem desktop={10} tabletLandscape={10}>
          <Declaration
            id="assumptionsPanel"
            hasAgreed={assumptionsAgreement.assumptionsAgreed}
            updateHasAgreed={(value) => {
              updateAssumptionsAgreement({ assumptionsAgreed: value });
              showValidation('assumptionsAgreed');
            }}
            error={getError('assumptionsAgreed')}
            questionId="assumptionsAgreed"
            questionText={confirmation_text}
            pageTitle={PageTitle.CheckYourDetails}>
            {subheading && <Subheading>{subheading}</Subheading>}
            <AggregatorList>
              {assumptions.assumptions.map((assumption) => (
                <AggregatorListItem key={generateKeyForElement(assumption)}>
                  {assumption.displayText && (
                    <RichTextWithModal
                      html={assumption.displayText}
                      pageTitle={PageTitle.CheckYourDetails}
                      aria-live="assertive"
                    />
                  )}
                </AggregatorListItem>
              ))}
            </AggregatorList>
            <EditButton
              onClick={() => {
                trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit assumptions');
                navigate(`${assumptions.anchorLinkToFirstAssumption}`);
              }}
              screenReaderText={edit_button_screenreader_text}>
              {edit_button_text}
            </EditButton>
          </Declaration>
        </GridItem>
      </Grid>
    </>
  );
};

export default AggregatorAssumptionsSection;

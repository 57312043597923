import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import emailValidationRules from 'helpers/emailValidationRules';
import phoneValidationRules from 'helpers/telephoneValidationRules';
import { CustomerDetails } from 'state/formData/customerDetails';

type CsContactDetailsErrorMessages = {
  csPetAboutYouQuestions: {
    main_telephone: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
      };
    };
    email: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYouQuestions {
      main_telephone {
        error_messages {
          missing
          too_short
          invalid_format
        }
      }
      email {
        error_messages {
          missing
          too_short
          invalid_format
        }
      }
    }
  }
`;

const useContactDetailsRules = (): ValidationRules<CustomerDetails> => {
  const errorMessages = useStaticQuery<CsContactDetailsErrorMessages>(query);

  return {
    customerTelephone: phoneValidationRules(
      errorMessages.csPetAboutYouQuestions.main_telephone.error_messages
    ),
    customerEmail: emailValidationRules(
      errorMessages.csPetAboutYouQuestions.email.error_messages
    ),
  };
};

export default useContactDetailsRules;

import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import {
  ErrorPanel,
  SuccessPanel,
  WarningPanel,
} from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import { borderPx } from '@rsa-digital/evo-shared-components/components/Panel/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';

const statusPanelStyling = css`
  display: flex;
  align-items: center;
  ${fonts.paragraph}
  margin: 0 0 ${spacing(2)};
  display: flex;
`;

export const StyledSuccessPanel = styled(SuccessPanel)`
  ${statusPanelStyling}
`;

export const StyledWarningPanel = styled(WarningPanel)`
  ${statusPanelStyling}
`;

export const StyledErrorPanel = styled(ErrorPanel)`
  ${statusPanelStyling}
`;

export const StyledInfoPanel = styled(SimplePanel)<{
  isCheckDetailsPage: boolean;
  children?: React.ReactNode;
}>`
  ${statusPanelStyling}
  border: ${borderPx}px solid ${colors.cardBorder};
  background-color: white;
  padding: ${spacing(3)};
  margin-bottom: ${(props) => (props.isCheckDetailsPage ? spacing(1) : spacing(4))};
  margin-top: ${(props) => (props.isCheckDetailsPage ? spacing(3) : 'auto')}
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(2)};
  margin-bottom: auto;
`;

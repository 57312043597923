import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from 'helpers/eventTracking';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion } from 'types/contentStack';
import { Question } from 'types/forms';

export type KeepingYouInformedQuestions = {
  keepingYouInformed: Question;
};

type csPetKeepingYouInformedQuestions = {
  csPetAboutYourPolicyQuestions: {
    keeping_you_informed: CsQuestion;
  };
};

const query = graphql`
  query {
    csPetAboutYourPolicyQuestions {
      keeping_you_informed {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
  }
`;

const useKeepingYouInformedQuestions = (
  pageTitle: PageTitle
): KeepingYouInformedQuestions => {
  const csQuestions = useStaticQuery<csPetKeepingYouInformedQuestions>(query);
  const { processQuestion } = useQuestionProcessor(pageTitle);

  return {
    keepingYouInformed: processQuestion(
      csQuestions.csPetAboutYourPolicyQuestions.keeping_you_informed
    ),
  };
};

export default useKeepingYouInformedQuestions;

import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { borderPx } from '@rsa-digital/evo-shared-components/components/Panel/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { InfoPanelWithIcon } from 'components/StatusPanel';

export const StyledInfoPanel = styled(InfoPanelWithIcon)<{
  isCheckDetailsPage: boolean;
}>`
  padding: ${spacing(2)};
  margin-top: ${({ isCheckDetailsPage }) => (isCheckDetailsPage ? spacing(2) : 0)};
  margin-bottom: ${({ isCheckDetailsPage }) => (isCheckDetailsPage ? 0 : undefined)};
  &&& {
    border: ${borderPx}px solid ${colors.neutral04};
    box-shadow: none;
    ul {
      padding-left: ${spacing(0)};
    }
    ${Grid} {
      padding: 0 ${spacing(2)};
    }
  }
`;

export const StyledFlexibleWidthDivider = styled.div`
  padding-top: ${spacing(3)};
  padding-bottom: ${spacing(1)};
`;

export const StyledPanelHeadingContainer = styled.div`
  display: flex;
`;

export const StyledPanelHeading = styled.p`
  font-weight: bold;
  margin: 0 0 ${spacing(1)} 0;
`;

import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import RichTextLarge from 'components/RichTextLarge';

export const PrivacyNotice = styled(RichTextLarge)`
  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(1)};
  `}
`;

export const StyledQuestionField = styled(QuestionField)`
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(4)}
    `}
`;

import { getCoverLevelFromQuoteOptions } from 'apiHelpers/quote/bundleCoverMapping';
import { AddOnType, useAddOnsForSelectedProduct } from 'businessLogic/addOns';
import { isCheapestPremier } from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestion/helpers';
import { initialQuoteOptions } from 'state/formData/quoteOptions';
import { useCurrentQuote } from './useCurrentQuote';

export const useQuoteSummaryDetailsValidity = (): boolean => {
  const quote = useCurrentQuote();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const { addOnsForSelectedProduct } = useAddOnsForSelectedProduct();

  /*  Details invalid if no cover level is selected */
  const coverLevelIsSelected = getCoverLevelFromQuoteOptions(quoteOptions) !== undefined;

  const additionalQuestionsAnsweredIfApplicable = (): boolean => {
    /*  Details are invalid if cheapest premier cover has been selected but the 
          additional question hasn't been answered
      */
    if (isCheapestPremier(quoteOptions)) {
      return quoteOptions.additionalQuestionCheapestPremier === true;
    }

    return true;
  };

  const addOnsSelectedOrDeclinedIfApplicable = (): boolean =>
    addOnsForSelectedProduct.every(
      (addOn) =>
        quoteOptions.addOnsSelected[addOn.content.type as AddOnType] !== undefined
    );

  return (
    coverLevelIsSelected &&
    additionalQuestionsAnsweredIfApplicable() &&
    addOnsSelectedOrDeclinedIfApplicable()
  );
};

import {
  lengthGreaterOrEqualTo,
  matchesRegex,
  required,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRule } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { EMAIL_REGEX } from './regexes';

type EmailValidationErrorMessages = {
  missing: string;
  too_short: string;
  invalid_format: string;
};

const emailValidationRules = <TData>(
  errorMessages: EmailValidationErrorMessages
): ValidationRule<string, TData>[] => {
  return [
    required(errorMessages.missing),
    lengthGreaterOrEqualTo(4, errorMessages.too_short),
    matchesRegex(EMAIL_REGEX, errorMessages.invalid_format),
  ];
};

export default emailValidationRules;

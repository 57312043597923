import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { PricingOverviewSectionProps } from './PricingOverviewSection';

type PricingOverviewSectionContent = {
  csPetPricingOverviewPanel: {
    monthly_payment_label: string;
    annual_payment_label: string;
    monthly_payment_details: string;
    annual_payment_details: string;
    payment_details: string;
  };
};

const query = graphql`
  query {
    csPetPricingOverviewPanel {
      monthly_payment_label
      annual_payment_label
      monthly_payment_details
      annual_payment_details
      payment_details
    }
  }
`;

export const usePricingOverviewSectionProps = (): PricingOverviewSectionProps => {
  const {
    csPetPricingOverviewPanel: {
      monthly_payment_label,
      annual_payment_label,
      monthly_payment_details,
      annual_payment_details,
      payment_details,
    },
  } = useStaticQuery<PricingOverviewSectionContent>(query);

  const theme = useTheme();
  const quote = useCurrentQuote();
  const replaceQuotePlaceholders = replacePlaceholdersRichText(quotePlaceholders, quote);

  const isAnnualPayment = quote.quoteOptions.isAnnualPayment ?? false;
  const paymentLabel = isAnnualPayment ? annual_payment_label : monthly_payment_label;
  const paymentSpecificDetails = isAnnualPayment
    ? annual_payment_details
    : monthly_payment_details;
  const prices = isAnnualPayment ? quote.price?.annualPrice : quote.price?.monthlyPrice;

  const specificDetails = paymentSpecificDetails
    ? replaceQuotePlaceholders(paymentSpecificDetails)
    : undefined;

  const generalDetails = payment_details
    ? replaceQuotePlaceholders(payment_details)
    : undefined;

  return {
    theme,
    paymentLabel,
    paymentSpecificDetails: specificDetails,
    paymentGeneralDetails: generalDetails,
    prices,
    petInfos: quote.petInfos,
  };
};

import { isPetAlreadyAgeNineForCover } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsExcessSectionProps = {
  csPetQuoteSummaryChooseExcess: {
    example_claim: {
      table: {
        fixed_excess: number;
      };
    };
  };
  csPetCheckYourDetailsDetailsSection: {
    excess: {
      fixed_excess: string;
      voluntary_excess: string;
      compulsory_excess: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcess {
      example_claim {
        table {
          fixed_excess
        }
      }
    }
    csPetCheckYourDetailsDetailsSection {
      excess {
        fixed_excess
        voluntary_excess
        compulsory_excess
      }
    }
  }
`;

const useExcessSection = (): Detail[][] => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      excess: { fixed_excess, voluntary_excess, compulsory_excess },
    },
    csPetQuoteSummaryChooseExcess: {
      example_claim: { table },
    },
  } = useStaticQuery<CsExcessSectionProps>(query);
  const quote = useCurrentQuote();
  const petInfos = quote?.petInfos ?? undefined;
  if (petInfos === undefined) {
    return [];
  }

  return petInfos.map((petInfo): Detail[] => [
    createDetail(fixed_excess, formatInPoundsWithSign(table.fixed_excess)),
    quote.policyInfo &&
    isPetAlreadyAgeNineForCover(petInfo.dob, quote.policyInfo.coverStartDate)
      ? createDetail(compulsory_excess, '20%')
      : createDetail(voluntary_excess, `${parseInt(petInfo.voluntaryExcessAmount, 10)}%`),
  ]);
};

export default useExcessSection;

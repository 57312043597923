import { graphql, useStaticQuery } from 'gatsby';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import getCurrentVetFeeLimit from 'helpers/useCurrentVetFeeLimit';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsCoverLevelSectionProps = {
  csPetCheckYourDetailsDetailsSection: {
    your_cover_level: {
      cover_type: string;
      vet_fees_limit: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      your_cover_level {
        cover_type
        vet_fees_limit
      }
    }
  }
`;

const useCoverLevelSection = (): Detail[] => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      your_cover_level: { cover_type, vet_fees_limit },
    },
  } = useStaticQuery<CsCoverLevelSectionProps>(query);
  const quote = useCurrentQuote();

  const product = getProductFromQuoteOptions(quote.quoteOptions);

  const getVetFeeLimitText = (): string => {
    const feeLimit = getCurrentVetFeeLimit(quote.quoteOptions);

    switch (product) {
      case Product.Basic: {
        return `Up to ${feeLimit} for each accident or illness OR up to 12 months from the date of treatment`;
      }
      case Product.Classic: {
        return `Up to ${feeLimit} for each accident or illness`;
      }
      case Product.Premier: {
        return `Up to ${feeLimit} for each accident or illness each year`;
      }
      default: {
        return '';
      }
    }
  };

  const getCoverTypeName = (): string => {
    switch (product) {
      case Product.Basic: {
        return 'Basic';
      }
      case Product.Classic: {
        return 'Classic';
      }
      case Product.Premier: {
        return 'Premier';
      }
      default: {
        return '';
      }
    }
  };

  return [
    createDetail(cover_type, getCoverTypeName()),
    createDetail(vet_fees_limit, getVetFeeLimitText()),
  ];
};

export default useCoverLevelSection;

import { InfoWrapper } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/styles';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import PredefinedPanel from '@rsa-digital/evo-shared-components/components/Panel/PredefinedPanel';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';

export const DisclaimerRichText = styled(RichTextWithModal)`
  margin-bottom: ${spacing(4)};
  text-align: center;
`;

export const DeclarationPanel = styled(SimplePanel)<{ children?: React.ReactNode }>`
  background: ${colors.neutral07};
  padding: ${spacing(4)} ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)} ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(6)} ${spacing(6)};
  `}
`;

export const DeclarationText = styled.div`
  margin-bottom: ${spacing(4)};
`;

export const QuestionPanel = styled(PredefinedPanel)<{ children?: React.ReactNode }>`
  && {
    box-shadow: none;
  }
  margin: -1px;
`;

export const CenteredQuestionField = styled(QuestionField)`
  text-align: center;
  ${InfoWrapper} {
    margin-bottom: ${spacing(1.5)};
  }
`;

export const GridWithPadding = styled(Grid)`
  padding: ${spacing(2)} ${spacing(2)} 0 ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)} ${spacing(4)} 0 ${spacing(4)};
  `}
`;

import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { isPetAlreadyAgeNineForCover } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { useSummaryText } from 'components/QuoteSummary/SummaryOfYourNeeds/SummaryOfYourNeedsTextSection/useSummaryText';
import { petPlaceholders } from 'helpers/placeholders/petPlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { SummaryOfNeedsSectionProps } from './SummaryOfNeedsSection';

type CsSummaryOfNeedsTextSection = {
  csPetQuoteSummaryOfYourNeeds: {
    text_summary: {
      per_pet_excess_statement_basic_classic_premier_under_9yrs: string;
      per_pet_excess_statement_basic_classic_premier_over_9yrs: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeeds {
      text_summary {
        per_pet_excess_statement_basic_classic_premier_under_9yrs
        per_pet_excess_statement_basic_classic_premier_over_9yrs
      }
    }
  }
`;

export const useSummaryOfNeedsSectionProps = (): SummaryOfNeedsSectionProps => {
  const {
    per_pet_excess_statement_basic_classic_premier_under_9yrs,
    per_pet_excess_statement_basic_classic_premier_over_9yrs,
  } = useStaticQuery<CsSummaryOfNeedsTextSection>(
    query
  ).csPetQuoteSummaryOfYourNeeds.text_summary;

  const summaryText = useSummaryText();
  const quote = useCurrentQuote();
  const theme = useTheme();

  const substituteQuotePlacholders = replacePlaceholdersRichText(
    quotePlaceholders,
    quote
  );

  const getExcessStatement = (pet: QuoteResponsePetInfo): string => {
    if (
      quote.policyInfo &&
      isPetAlreadyAgeNineForCover(pet.dob, quote.policyInfo?.coverStartDate)
    ) {
      return per_pet_excess_statement_basic_classic_premier_over_9yrs;
    }
    return per_pet_excess_statement_basic_classic_premier_under_9yrs;
  };

  const substituteExcessStatementPetPlaceholders = (
    pet: QuoteResponsePetInfo
  ): string => {
    const excessStatement = getExcessStatement(pet);
    return replacePlaceholdersRichText(petPlaceholders, pet)(excessStatement);
  };

  const excessStatements = quote.petInfos?.map((pet) =>
    substituteExcessStatementPetPlaceholders(pet)
  );

  return {
    theme,
    textBeforeStatement: summaryText
      ? substituteQuotePlacholders(summaryText.text_before_statement)
      : undefined,
    textAfterStatement: summaryText
      ? substituteQuotePlacholders(summaryText.text_after_statement)
      : undefined,
    excessStatements,
  };
};

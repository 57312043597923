import { Option } from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { graphql, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import React from 'react';
import BreedPlaybackPanel, {
  convertQuoteResponsePetInfoToPetObject,
} from 'components/BreedPlaybackPanel';
import { useCatBreedTypeOptions } from 'forms/AboutYourPetsForm/useCatBreedTypeOptions';
import { useDogBreedTypeOptions } from 'forms/AboutYourPetsForm/useDogBreedTypeOptions';
import { useMongrelSizeOptions } from 'forms/AboutYourPetsForm/useMongrelSizeOptions';
import { getAgeShort } from 'helpers/ageHelpers';
import { notKnownValueMasking } from 'helpers/booleanWithUnknownHelper';
import { PageTitle } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import {
  mongrelSize_LARGE,
  mongrelSize_MEDIUM,
  mongrelSize_SMALL,
  petType_CAT,
} from 'helpers/referenceDataConstants';
import { capitaliseFirstCharacter } from 'helpers/stringHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useReferenceData from 'state/referenceData/useReferenceData';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsPetSectionProps = {
  csPetCheckYourDetailsDetailsSection: {
    about_your_pet: {
      age: string;
      amount_paid: string;
      chipped_or_tagged: string;
      gender: string;
      in_good_health: string;
      spayed_or_neutered: string;
    };
    about_your_pet_cat_specific_details: {
      breed: string;
    };
    about_your_pet_dog_specific_details: {
      behaviour_complaints: string;
      breed: string;
      legal_action: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      about_your_pet {
        age
        amount_paid
        chipped_or_tagged
        gender
        in_good_health
        spayed_or_neutered
      }
      about_your_pet_cat_specific_details {
        breed
      }
      about_your_pet_dog_specific_details {
        behaviour_complaints
        breed
        legal_action
      }
    }
  }
`;

const generateSinglePetSection = (
  pet: QuoteResponsePetInfo,
  csPetSectionProps: CsPetSectionProps,
  breedTypes: Option[],
  sizeOptions: Option[],
  breeds: Option[],
  genders: Option[]
): Detail[] => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      about_your_pet: {
        age,
        amount_paid,
        chipped_or_tagged,
        gender,
        in_good_health,
        spayed_or_neutered,
      },
      about_your_pet_cat_specific_details,
      about_your_pet_dog_specific_details,
    },
  } = csPetSectionProps;

  const { petName } = pet;
  const petGender = createDetail(
    petNameReplacer(trimEnd(petName), gender),
    genders.find((genderOption) => genderOption.value === pet.petGender)?.name
  );
  const petAge = createDetail(
    petNameReplacer(trimEnd(petName), age),
    getAgeShort(pet.dob)
  );
  const formattedAmountPaid = `${formatInPoundsWithSign(pet.initialCostPound)}`;
  const amountPaid = createDetail(
    petNameReplacer(petName, amount_paid),
    formattedAmountPaid === '' ? 'Nothing paid' : formattedAmountPaid
  );
  const isPetSpayedValue = notKnownValueMasking(pet.isPetSpayed);
  const spayedOrNeutered = createDetail(
    petNameReplacer(petName, spayed_or_neutered),
    capitaliseFirstCharacter(isPetSpayedValue)
  );
  const isPetChippedOrTaggedValue = notKnownValueMasking(pet.isPetChippedOrTagged);
  const chippedOrTagged = createDetail(
    petNameReplacer(petName, chipped_or_tagged),
    capitaliseFirstCharacter(isPetChippedOrTaggedValue)
  );
  const inGoodHealth = createDetail(
    petNameReplacer(petName, in_good_health),
    pet.isPetShowingIllness ? 'No' : 'Yes'
  );

  const catBreed = createDetail(
    petNameReplacer(trimEnd(petName), about_your_pet_cat_specific_details.breed),
    `${breedTypes.find((breedType) => breedType.value === pet.petBreedType)?.name}, ${
      breeds.find((breed) => breed.value === pet.petBreed)?.name
    }`,
    <BreedPlaybackPanel
      pageTitle={PageTitle.CheckYourDetails}
      petDetails={convertQuoteResponsePetInfoToPetObject(pet)}
    />
  );

  const dogBreed = createDetail(
    petNameReplacer(trimEnd(petName), about_your_pet_dog_specific_details.breed),
    [mongrelSize_SMALL, mongrelSize_MEDIUM, mongrelSize_LARGE].includes(pet.petBreedType)
      ? `Mongrel or mixed breed, ${
          sizeOptions.find((sizeOption) => sizeOption.value === pet.petBreedType)?.name
        }`
      : `${breedTypes.find((breedType) => breedType.value === pet.petBreedType)?.name}, ${
          breeds.find((breed) => breed.value === pet.petBreed)?.name
        }`,
    <BreedPlaybackPanel
      pageTitle={PageTitle.CheckYourDetails}
      petDetails={convertQuoteResponsePetInfoToPetObject(pet)}
    />
  );

  if (pet.petType === petType_CAT) {
    return [
      catBreed,
      inGoodHealth,
      petGender,
      petAge,
      amountPaid,
      spayedOrNeutered,
      chippedOrTagged,
    ];
  }

  return [dogBreed, inGoodHealth, petGender, petAge, amountPaid, spayedOrNeutered];
};

const usePetSection = (): Detail[][] => {
  const csData = useStaticQuery<CsPetSectionProps>(query);
  const quote = useCurrentQuote();

  const gendersRefData = useReferenceData('genders')?.genders ?? [];
  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];
  const dogBreedTypes = useDogBreedTypeOptions();
  const catBreedTypes = useCatBreedTypeOptions();
  const dogSizeOptions = useMongrelSizeOptions();

  const petInfos = quote?.petInfos ?? undefined;
  if (petInfos === undefined) {
    return [];
  }

  return petInfos.map((petInfo): Detail[] =>
    generateSinglePetSection(
      petInfo,
      csData,
      petInfo.petType === petType_CAT ? catBreedTypes : dogBreedTypes,
      petInfo.petType === petType_CAT ? [] : dogSizeOptions,
      petInfo.petType === petType_CAT ? catBreedsRefData : dogBreedsRefData,
      gendersRefData
    )
  );
};

export default usePetSection;

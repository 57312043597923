import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import SectionHeading from 'forms/SectionHeading';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import { PolicyDetails, usePolicyDetails } from 'state/formData/policyDetails';
import useKeepingYouInformedQuestions from './questions';
import { PrivacyNotice, StyledQuestionField } from './styles';

type KeepingYouInformedFormDetails = Pick<PolicyDetails, 'keepingYouInformed'>;

type KeepingYouInformedFormProps = {
  formValidation: {
    getError: FieldFunction<KeepingYouInformedFormDetails, string | undefined>;
    showValidation: FieldFunction<KeepingYouInformedFormDetails, void>;
  };
  pageTitle: PageTitle;
  sectionHeadingId?: string;
};

type csKeepingYouInformedDetails = {
  csPetAboutYourPolicyQuestions: {
    keeping_you_informed: {
      heading: string;
      privacy_notice: string | undefined;
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPolicyQuestions {
      keeping_you_informed {
        heading
        privacy_notice
      }
    }
  }
`;

const KeepingYouInformedForm: React.FC<KeepingYouInformedFormProps> = ({
  formValidation,
  pageTitle,
  sectionHeadingId,
}) => {
  const questions = useKeepingYouInformedQuestions(pageTitle);

  const [policyDetails, updatePolicyDetails] = usePolicyDetails();

  const { heading, privacy_notice } = useStaticQuery<csKeepingYouInformedDetails>(
    query
  ).csPetAboutYourPolicyQuestions.keeping_you_informed;

  return (
    <>
      <SectionHeading heading={heading} id={sectionHeadingId} />
      {privacy_notice && (
        <Grid alignLeft>
          <GridItem desktop={8} tabletLandscape={8}>
            <PrivacyNotice
              html={privacy_notice}
              onLinkClick={trackRichTextLinkClick(pageTitle)}
              pageTitle={pageTitle}
            />
          </GridItem>
        </Grid>
      )}
      <StyledQuestionField
        question={questions.keepingYouInformed}
        errorText={formValidation.getError('keepingYouInformed')}>
        <BooleanRadioInput
          id="keepingYouInformed"
          value={policyDetails.keepingYouInformed}
          analyticsDescription="Keeping you informed"
          onChange={(value) => {
            updatePolicyDetails({ keepingYouInformed: value });
          }}
          data-cy="keepInformedRadio"
        />
      </StyledQuestionField>
    </>
  );
};

export default KeepingYouInformedForm;

import { graphql, useStaticQuery } from 'gatsby';
import { formatLongDateWithOrdinalSuffix } from 'helpers/dateHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsJointPolicyholderSectionProps = {
  csPetCheckYourDetailsDetailsSection: {
    about_your_joint_policyholder: {
      date_of_birth: string;
      first_name: string;
      last_name: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      about_your_joint_policyholder {
        date_of_birth
        first_name
        last_name
      }
    }
  }
`;

const useJointPolicyholderSection = (): Detail[] => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      about_your_joint_policyholder: { date_of_birth, first_name, last_name },
    },
  } = useStaticQuery<CsJointPolicyholderSectionProps>(query);
  const quote = useCurrentQuote();

  const jointPolicyholderInfo = quote.policyInfo?.joinPolicyHolder;
  if (quote.policyInfo?.includeJoinPolicyHolder && jointPolicyholderInfo) {
    return [
      createDetail(first_name, jointPolicyholderInfo.firstName),
      createDetail(last_name, jointPolicyholderInfo.lastName),
      createDetail(
        date_of_birth,
        formatLongDateWithOrdinalSuffix(jointPolicyholderInfo.dob)
      ),
    ];
  }
  return [];
};

export default useJointPolicyholderSection;

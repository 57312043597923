import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { PanelOptions } from '@rsa-digital/evo-shared-components/components/Panel/PredefinedPanel/predefinedStyles';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import { PageTitle } from 'helpers/eventTracking';
import {
  CenteredQuestionField,
  DeclarationPanel,
  DeclarationText,
  DisclaimerRichText,
  GridWithPadding,
  QuestionPanel,
} from './styles';

export type DeclarationDetails = {
  hasAgreedToDeclaration: boolean | undefined;
};

type DeclarationProps = {
  id: string;
  hasAgreed: boolean | undefined;
  updateHasAgreed: (update: boolean) => void;
  error?: string;
  questionId: string;
  questionText: string;
  disclaimerText?: string;
  pageTitle: PageTitle;
} & ComponentProps;

const Declaration: React.FC<DeclarationProps> = ({
  id,
  hasAgreed,
  updateHasAgreed,
  error,
  questionId,
  questionText,
  disclaimerText,
  pageTitle,
  children,
  ...rest
}) => (
  <DeclarationPanel
    {...componentProps(rest)}
    id={id}
    borderShadows={{ displayOutset: true }}>
    <DeclarationText>{children}</DeclarationText>
    <QuestionPanel
      id={`${questionId}-panel`}
      panelType={error ? PanelOptions.ERROR : PanelOptions.NONE}
      cornerLabel={{
        icon: error ? 'error' : undefined,
      }}
      bottomLabelId={`${questionId}-panel-error`}
      bottomLabelText={error}
      bottomLabelRole="alert"
      bottomLabelAriaLive="assertive">
      <GridWithPadding>
        <GridItem desktop={7} tabletLandscape={7} tabletPortrait={7}>
          <CenteredQuestionField
            id={`${questionId}-label`}
            question={{ questionText }}
            gridItemProps={{
              desktop: 7,
              tabletLandscape: 7,
              tabletPortrait: 7,
              mobile: 4,
            }}>
            <BooleanRadioInput
              id={questionId}
              value={hasAgreed}
              onChange={updateHasAgreed}
              aria-describedby={`${questionId}-error`}
            />
          </CenteredQuestionField>
        </GridItem>
        {disclaimerText && (
          <GridItem desktop={9} tabletLandscape={9}>
            <DisclaimerRichText
              data-cy={`${id}-disclaimer-text`}
              html={disclaimerText}
              pageTitle={pageTitle}
            />
          </GridItem>
        )}
      </GridWithPadding>
    </QuestionPanel>
  </DeclarationPanel>
);

export default Declaration;

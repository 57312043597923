import { AddOnType, useAddOnsForSelectedProduct } from 'businessLogic/addOns';
import { useCurrentQuote } from 'helpers/useCurrentQuote';

export type AddOnDetail = {
  isIncluded: boolean;
  addOnLabel: string;
};

const useAddOnSection = (): AddOnDetail[] => {
  const quote = useCurrentQuote();
  const { addOnsSelected } = quote.quoteOptions;

  const addOns = useAddOnsForSelectedProduct();
  return addOns.addOnsForSelectedProduct
    .map((addOn) => ({
      isIncluded: addOnsSelected[addOn.content.type as AddOnType],
      addOnLabel: addOn.content.label,
    }))
    .filter((addOn) => addOn.isIncluded !== null) as AddOnDetail[];
};

export default useAddOnSection;

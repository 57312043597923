import { graphql, useStaticQuery } from 'gatsby';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsAdditionalInformationSectionProps = {
  csPetCheckYourDetailsDetailsSection: {
    additional_information: {
      number_of_pets_in_household: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      additional_information {
        number_of_pets_in_household
      }
    }
  }
`;

const useAdditionalInformationSection = (): Detail[] => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      additional_information: { number_of_pets_in_household },
    },
  } = useStaticQuery<CsAdditionalInformationSectionProps>(query);
  const quote = useCurrentQuote();

  const numberOfPetsInHousehold = quote?.policyInfo?.numberPetInHousehold;

  return [createDetail(number_of_pets_in_household, `${numberOfPetsInHousehold}`)];
};

export default useAdditionalInformationSection;

import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { Detail } from 'components/CheckYourDetails/DetailsSection/DetailGroupSection';
import useAboutYouSection from 'components/CheckYourDetails/DetailsSection/useAboutYouSection';
import useAddOnSection from 'components/CheckYourDetails/DetailsSection/useAddOnSection';
import useAdditionalInformationSection from 'components/CheckYourDetails/DetailsSection/useAdditionalInformationSection';
import useCoverDatesSection from 'components/CheckYourDetails/DetailsSection/useCoverDatesSection';
import useCoverLevelSection from 'components/CheckYourDetails/DetailsSection/useCoverLevelSection';
import useExcessSections from 'components/CheckYourDetails/DetailsSection/useExcessSections';
import useJointPolicyholderSection from 'components/CheckYourDetails/DetailsSection/useJointPolicyholderSection';
import usePetSections from 'components/CheckYourDetails/DetailsSection/usePetSections';
import useQuoteReference from 'components/CheckYourDetails/DetailsSection/useQuoteReference';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { DetailsSectionProps } from './DetailsSection';

type DetailsSectionContent = {
  csPetCheckYourDetailsDetailsSection: {
    about_you: {
      about_you_heading: string;
    };
    about_your_joint_policyholder: {
      about_your_joint_policyholder_heading: string;
    };
    about_your_pet: {
      about_your_pet_heading: string;
    };
    additional_information: {
      additional_information_heading: string;
    };
    excess: {
      excess_heading: string;
      excess_section_description: string;
      voluntary_excess_disclaimer: string;
    };
    your_cover_dates: {
      your_cover_dates_heading: string;
    };
    your_cover_level: {
      your_cover_level_heading: string;
      add_on_details: {
        included_text: string;
        not_included_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      about_you {
        about_you_heading
      }
      about_your_joint_policyholder {
        about_your_joint_policyholder_heading
      }
      about_your_pet {
        about_your_pet_heading
      }
      additional_information {
        additional_information_heading
      }
      excess {
        excess_heading
        excess_section_description
        voluntary_excess_disclaimer
      }
      your_cover_dates {
        your_cover_dates_heading
      }
      your_cover_level {
        your_cover_level_heading
        add_on_details {
          included_text
          not_included_text
        }
      }
    }
  }
`;

export const useDetailsSectionProps = (): DetailsSectionProps => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      about_you,
      about_your_joint_policyholder,
      about_your_pet,
      additional_information,
      excess: { excess_heading, excess_section_description, voluntary_excess_disclaimer },
      your_cover_dates,
      your_cover_level: {
        your_cover_level_heading,
        add_on_details: { included_text, not_included_text },
      },
    },
  } = useStaticQuery<DetailsSectionContent>(query);
  const quote = useCurrentQuote();
  const petInfos = quote.petInfos ?? [];
  const jointPolicyholderDetails = useJointPolicyholderSection();
  const petsDetails = usePetSections();
  const petsExcess = useExcessSections();
  const theme = useTheme();

  const addOnDetails = useAddOnSection();
  const mappedAddOnDetails = addOnDetails.map(
    (detail): Detail => ({
      label: detail.addOnLabel,
      value: detail.isIncluded ? included_text : not_included_text,
    })
  );

  const quoteReference = useQuoteReference();
  const yourCoverDatesSectionProps = {
    sectionHeading: your_cover_dates.your_cover_dates_heading,
    details: useCoverDatesSection(),
  };
  const yourCoverLevelSectionProps = {
    sectionHeading: your_cover_level_heading,
    details: useCoverLevelSection().concat(mappedAddOnDetails),
  };
  const aboutYouSectionProps = {
    sectionHeading: about_you.about_you_heading,
    details: useAboutYouSection(),
  };
  const jointPolicyholderSectionProps = quote.policyInfo?.includeJoinPolicyHolder
    ? {
        sectionHeading:
          about_your_joint_policyholder.about_your_joint_policyholder_heading,
        details: jointPolicyholderDetails,
      }
    : undefined;
  const additionalInformationSectionProps = {
    sectionHeading: additional_information.additional_information_heading,
    details: useAdditionalInformationSection(),
  };
  const yourPetsSectionProps = petInfos.map((petInfo, index) => ({
    sectionHeading: petNameReplacer(
      petInfo.petName,
      about_your_pet.about_your_pet_heading
    ),
    details: petsDetails[index],
  }));
  const excessSectionProps = petInfos.map((petInfo, index) => ({
    sectionHeading: petNameReplacer(petInfo.petName, excess_heading),
    details: petsExcess[index],
    sectionDescription: excess_section_description,
    sectionDisclaimer: voluntary_excess_disclaimer,
  }));

  return {
    quoteReference,
    yourCoverDatesSectionProps,
    yourCoverLevelSectionProps,
    aboutYouSectionProps,
    additionalInformationSectionProps,
    jointPolicyholderSectionProps,
    yourPetsSectionProps,
    excessSectionProps,
    theme,
  };
};

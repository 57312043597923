import { FullAddress } from 'apiHelpers/address/address';
import { CustomerDetails } from 'state/formData/customerDetails';
import { ReferenceDataOption } from 'types/referenceData';

export const fullAddressToString = (address: FullAddress): string => {
  return [
    address.flatNumber,
    address.flatName,
    address.houseName,
    address.houseNumber,
    address.street,
    address.town,
  ]
    .filter(Boolean)
    .join(', ');
};

export const manualAddressToString = (
  customerDetails: CustomerDetails,
  countiesRefData: ReferenceDataOption[]
): string => {
  return [
    customerDetails.flatNameOrNumber,
    customerDetails.houseNameOrNumber,
    customerDetails.street,
    customerDetails.town,
    countiesRefData.find((county) => county.value === customerDetails.county)?.name ?? '',
    customerDetails.postcode,
  ]
    .filter(Boolean)
    .join(', ');
};

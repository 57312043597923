import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';
import { CsApplicableProducts } from 'types/contentStack';
import {
  PolicyDocumentsPlaceholderData,
  policyDocumentsPlaceholders,
} from './placeholders/policyDocumentsPlaceholders';
import { replacePlaceholdersRichText } from './placeholders/replaceCsPlaceholders';
import { appliesToSelection } from './productHelpers';
import { useCurrentQuote } from './useCurrentQuote';
import useCurrentVetFeeLimit from './useCurrentVetFeeLimit';
import { usePolicyDocumentLink } from './usePolicyDocumentLink';

export type Declaration = {
  hasAgreed: boolean | undefined;
  content: CsDeclaration;
};

export type CsDeclaration = {
  applicable_products: CsApplicableProducts;
  body_text: string;
  agreement_question: string;
  disclaimer_text?: string;
  question_missed_error_text: string;
  answered_no_error_text: string;
};

type CsUseDeclarations = {
  csPetCheckYourDetailsDeclaration: {
    declaration: CsDeclaration[];
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetailsDeclaration {
      declaration {
        applicable_products {
          product_selector
        }
        body_text
        agreement_question
        disclaimer_text
        question_missed_error_text
        answered_no_error_text
      }
    }
  }
`;

const useDeclarations = (): {
  declarations: Declaration[];
  declarationRules: ValidationRules<{ declarations: Declaration[] }>;
  updateDeclarationAgreement: (update: boolean, index: number) => void;
} => {
  const { declaration: declarationContent } = useStaticQuery<CsUseDeclarations>(
    query
  ).csPetCheckYourDetailsDeclaration;

  const quote = useCurrentQuote();

  const [declarations, setDeclarations] = useState<Declaration[]>([]);

  useEffect(() => {
    const initialDeclarations = declarationContent
      .filter((singleDeclaration) =>
        appliesToSelection(quote.quoteOptions, singleDeclaration.applicable_products)
      )
      .map((content) => ({ hasAgreed: undefined, content }));

    if (declarations.length === 0) {
      setDeclarations(initialDeclarations);
    }
  }, [declarationContent, declarations, quote.quoteOptions, setDeclarations]);

  const getDeclarationRules = (): ValidationRules<{ declarations: Declaration[] }> => {
    const rules: ValidationRules<Declaration> = {
      hasAgreed: [
        {
          validityCondition: (value) => value === true || value === false,
          errorMessage: 'missing',
        },
        {
          validityCondition: (value) => value === true && value !== undefined,
          errorMessage: 'invalid',
        },
      ],
    };

    return {
      declarations: {
        hasAgreed: rules.hasAgreed?.map(({ validityCondition, ...rest }) => ({
          ...rest,
          validityCondition: (v, data, i) => validityCondition(v, data.declarations[i]),
        })),
      },
    };
  };

  const declarationRules = getDeclarationRules();

  const updateDeclarationAgreement = (update: boolean, index: number): void => {
    setDeclarations((prevState) => {
      if (!prevState) {
        return prevState;
      }
      return [
        ...prevState.slice(0, index),
        {
          ...prevState[index],
          hasAgreed: update,
        },
        ...prevState.slice(index + 1),
      ];
    });
  };

  const { quoteOptions } = useCurrentQuote();

  const policyDocumentsPlaceholderData: PolicyDocumentsPlaceholderData = {
    productInformationDocument: usePolicyDocumentLink(quoteOptions),
    coverLimit: useCurrentVetFeeLimit(quote.quoteOptions),
  };

  const substitutePlaceholders = replacePlaceholdersRichText(
    policyDocumentsPlaceholders,
    policyDocumentsPlaceholderData
  );

  const processedDeclarations: Declaration[] = declarations.map((declaration) => ({
    hasAgreed: declaration.hasAgreed,
    content: {
      ...declaration.content,
      body_text: substitutePlaceholders(declaration.content.body_text),
    },
  }));

  return {
    declarations: processedDeclarations,
    updateDeclarationAgreement,
    declarationRules,
  };
};

export default useDeclarations;

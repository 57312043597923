import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { Declaration } from 'helpers/useDeclarations';
import { DeclarationSectionProps } from './DeclarationSection';

type DeclarationSectionContent = {
  csPetCheckYourDetailsDeclaration: {
    section_heading: string;
    section_description: string;
  };
  csPetGlobalConfig: {
    boolean_answer_text: {
      yes: string;
      no: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDeclaration {
      section_heading
      section_description
    }
    csPetGlobalConfig {
      boolean_answer_text {
        yes
        no
      }
    }
  }
`;

export const getAnswerText = (
  hasAgreed: boolean | undefined,
  booleanAnswerText: { yes: string; no: string }
): string => {
  if (hasAgreed !== undefined) {
    return hasAgreed ? booleanAnswerText.yes : booleanAnswerText.no;
  }

  return 'No selection made';
};

export const useDeclarationSectionProps = (
  declarations: Declaration[]
): DeclarationSectionProps => {
  const {
    csPetCheckYourDetailsDeclaration: { section_heading, section_description },
    csPetGlobalConfig: { boolean_answer_text },
  } = useStaticQuery<DeclarationSectionContent>(query);

  const theme = useTheme();

  const processedDeclarations = declarations.map((declaration) => ({
    bodyText: declaration.content.body_text,
    questionText: declaration.content.agreement_question,
    questionAnswer: getAnswerText(declaration.hasAgreed, boolean_answer_text),
    disclaimerText: declaration.content.disclaimer_text,
  }));

  return {
    theme,
    heading: section_heading,
    summary: section_description,
    declarations: processedDeclarations,
  };
};

import {
  mongrelSize_LARGE,
  mongrelSize_MEDIUM,
  mongrelSize_SMALL,
} from 'helpers/referenceDataConstants';
import { capitaliseFirstCharacter } from 'helpers/stringHelpers';
import { Pet } from 'state/formData/petsDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import { useDefaultPetNamePlaceholder } from './dynamicPetNameHelpers';
import { CsPlaceholders } from './replaceCsPlaceholders';

export const useAboutYourPetPlaceholders = (): CsPlaceholders<Pet> => {
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];
  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const defaultPetNameText = useDefaultPetNamePlaceholder();

  const getReadableMongrelSize = (mongrelSize: string): string => {
    switch (mongrelSize) {
      case mongrelSize_SMALL:
        return 'small';
      case mongrelSize_MEDIUM:
        return 'medium';
      case mongrelSize_LARGE:
        return 'large';
      default:
        return '';
    }
  };

  return {
    petName: {
      getSubstitutionText: (pet) =>
        pet.petName.length > 0
          ? capitaliseFirstCharacter(pet.petName)
          : capitaliseFirstCharacter(defaultPetNameText),
      isPii: true,
    },
    petBreedName: {
      getSubstitutionText: (pet) =>
        dogBreedsRefData.find((breed) => breed.value === pet.dogBreedName)?.name ??
        catBreedsRefData.find((breed) => breed.value === pet.catBreedName)?.name ??
        '',
      isPii: false,
    },
    petMongrelSize: {
      getSubstitutionText: (pet) => getReadableMongrelSize(pet.mongrelSize),
      isPii: false,
    },
  };
};

import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import RichTextWithModal from 'components/RichTextWithModal';
import { InfoPanelWithIcon } from 'components/StatusPanel';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { useAboutYourPetPlaceholders } from 'helpers/placeholders/aboutYourPetPlaceholders';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import {
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  dogBreedType_CROSS_BREED,
  dogBreedType_PEDIGREE,
  petType_CAT,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { Pet } from 'state/formData/petsDetails';
import { CsIcon } from 'types/contentStack';
import {
  StyledFlexibleWidthDivider,
  StyledInfoPanel,
  StyledPanelHeading,
  StyledPanelHeadingContainer,
} from './styles';
import { useCatEligibilityStatement } from '../../forms/AboutYourPetsForm/useCatEligibilityStatement';

type BreedInfoPanelData = {
  csPetAboutYourPetCatQuestions: {
    pedigree_cat_breed_name: {
      breed_info_panel_text: string;
    };
    non_pedigree_cat_breed_name: {
      breed_info_panel_text: string;
    };
  };
  csPetAboutYourPetDogQuestions: {
    pedigree_dog_breed_name: {
      breed_info_panel_text: string;
    };
    cross_breed_dog_breed_name: {
      breed_info_panel_text: string;
    };
    mongrel_size: {
      breed_info_panel_text: string;
    };
  };
  csPetCheckYourDetailsDetailsSection: {
    about_your_pet: {
      statements_heading: string;
      statements: string;
      custom_bullet_icon: [CsIcon];
    };
    statements: string;
  };
};

export const query = graphql`
  query {
    csPetAboutYourPetCatQuestions {
      pedigree_cat_breed_name {
        breed_info_panel_text
      }
      non_pedigree_cat_breed_name {
        breed_info_panel_text
      }
    }
    csPetAboutYourPetDogQuestions {
      pedigree_dog_breed_name {
        breed_info_panel_text
      }
      cross_breed_dog_breed_name {
        breed_info_panel_text
      }
      mongrel_size {
        breed_info_panel_text
      }
    }
    csPetCheckYourDetailsDetailsSection {
      about_your_pet {
        statements_heading
        statements
        custom_bullet_icon {
          icon_code
        }
        statements
      }
    }
  }
`;

type BreedInfoPanelProps = {
  petDetails: Pet;
  pageTitle: PageTitle;
};

// To populate the breed playback panel we need the Pet information to be in a Pet object
// Here we will convert our QuoteResponsePetInfo to type Pet
// We are only populating the fields required for the breed playback panel, so be aware of this if re-using in future
export const convertQuoteResponsePetInfoToPetObject = (
  petInfo: QuoteResponsePetInfo
): Pet => {
  return {
    petName: petInfo.petName,
    petType: petInfo.petType,
    petIsEligible: undefined,
    dogBreedType: petInfo.petBreedType,
    dogBreedName: petInfo.petBreed,
    mongrelSize: petInfo.petBreedType,
    catBreedType: petInfo.petBreedType,
    catBreedName: petInfo.petBreed,
    petGender: petInfo.petGender,
    petSpayed: '',
    petChipped: '',
    petCost: undefined,
    dogComplaints: undefined,
    petLivesWithYou: undefined,
    petInGoodHealth: undefined,
    dogLegalAction: undefined,
    petDob: {
      day: undefined,
      month: undefined,
      year: undefined,
    },
    twentyPercentExcessConfirmation: petInfo.twentyPercentExcessConfirmation,
    petForMakeMoney: undefined,
    dogBreedListLink: undefined,
  };
};

const BreedPlaybackPanel: React.FC<BreedInfoPanelProps> = ({ petDetails, pageTitle }) => {
  const {
    csPetAboutYourPetCatQuestions: {
      pedigree_cat_breed_name,
      non_pedigree_cat_breed_name,
    },
    csPetAboutYourPetDogQuestions: {
      pedigree_dog_breed_name,
      cross_breed_dog_breed_name,
      mongrel_size,
    },
    csPetCheckYourDetailsDetailsSection: { about_your_pet },
  } = useStaticQuery<BreedInfoPanelData>(query);
  const petIsCat = petDetails.petType === petType_CAT;
  const petIsDog = petDetails.petType === petType_DOG;
  const catEligibilityStatement = useCatEligibilityStatement();

  const showPedigreeCatBreedNameInfoPanel =
    petIsCat && petDetails.catBreedType === catBreedType_PEDIGREE;
  const showNonPedigreeCatBreedNameInfoPanel =
    petIsCat && petDetails.catBreedType === catBreedType_NON_PEDIGREE;
  const showPedigreeDogBreedNameInfoPanel =
    petIsDog && petDetails.dogBreedType === dogBreedType_PEDIGREE;
  const showCrossBreedDogBreedNameInfoPanel =
    petIsDog && petDetails.dogBreedType === dogBreedType_CROSS_BREED;

  const aboutYourPetPlaceholders = useAboutYourPetPlaceholders();
  const substitutePetPlaceholders = replacePlaceholdersRichText(
    aboutYourPetPlaceholders,
    petDetails
  );

  const getBreedInfoPanelText = (): string => {
    if (showPedigreeCatBreedNameInfoPanel) {
      return substitutePetPlaceholders(pedigree_cat_breed_name.breed_info_panel_text);
    }

    if (showNonPedigreeCatBreedNameInfoPanel) {
      return substitutePetPlaceholders(non_pedigree_cat_breed_name.breed_info_panel_text);
    }
    if (showPedigreeDogBreedNameInfoPanel) {
      return substitutePetPlaceholders(pedigree_dog_breed_name.breed_info_panel_text);
    }

    if (showCrossBreedDogBreedNameInfoPanel) {
      return substitutePetPlaceholders(cross_breed_dog_breed_name.breed_info_panel_text);
    }

    return substitutePetPlaceholders(mongrel_size.breed_info_panel_text);
  };

  const getPetDogQuestionsStatementList = (): string => {
    return substitutePetPlaceholders(about_your_pet.statements);
  };

  const getPetCatQuestionsStatementList = (): string => {
    return substitutePetPlaceholders(catEligibilityStatement.statement_list);
  };

  const icon = unwrapSingleton(about_your_pet?.custom_bullet_icon)?.icon_code;

  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();
  const [assumptionsAgreedOnRender] = useState(assumptionsAgreement.assumptionsAgreed);
  const showAssumptions = !!assumptions && !assumptionsAgreedOnRender;

  return (
    <div>
      <FieldGrid alignLeft>
        <InfoPanelWithIcon
          data-cy="pet-breed-info-panel"
          isCheckDetailsPage={pageTitle === PageTitle.CheckYourDetails}>
          <RichTextWithModal html={getBreedInfoPanelText()} pageTitle={pageTitle} />
        </InfoPanelWithIcon>
      </FieldGrid>
      {pageTitle === PageTitle.CheckYourDetails && !showAssumptions && (
        <>
          <StyledFlexibleWidthDivider>
            <FlexibleWidthDivider aria-hidden />
          </StyledFlexibleWidthDivider>
          <FieldGrid alignLeft>
            <StyledInfoPanel
              data-cy="pet-questions-statement-list"
              isCheckDetailsPage={pageTitle === PageTitle.CheckYourDetails}>
              <Grid alignLeft>
                <StyledPanelHeadingContainer>
                  <StyledPanelHeading>
                    {petNameReplacer(
                      petDetails.petName,
                      about_your_pet.statements_heading
                    )}
                  </StyledPanelHeading>
                </StyledPanelHeadingContainer>
                <RichTextWithModal
                  html={
                    petIsDog
                      ? getPetDogQuestionsStatementList()
                      : getPetCatQuestionsStatementList()
                  }
                  pageTitle={pageTitle}
                  customBulletIcon={icon}
                />
              </Grid>
            </StyledInfoPanel>
          </FieldGrid>
        </>
      )}
    </div>
  );
};

export default BreedPlaybackPanel;

import quoteClient from 'apiHelpers/quoteClient';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { trackReCaptchaScore } from 'helpers/eventTracking';
import getRecaptchaToken from 'helpers/reCaptchaHelpers';
import {
  RECAPTCHA_SKIP_KEY_SESSION_KEY,
  retrieveData,
} from 'helpers/sessionStorageHelpers';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import useLoadingState from 'helpers/useLoadingState';
import { RootState } from 'state/createStore';
import {
  UPDATE_ASSUMPTIONS_AGREEMENT,
  UpdateAssumptionsAgreementAction,
} from 'state/formData/assumptionsAgreement';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { usePetsDetails } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import { useReferralCodes } from 'state/formData/referralCodes';
import { UPDATE_QUOTE, UpdateQuoteAction } from 'state/quote/quote';
import mapFormToQuote, { mapFormToRequote } from './formToQuoteMapping';
import { Quote } from './quoteResponse';

const useGenerateQuote = (
  shouldNotInvalidateAssumptions = false
): {
  isLoading: boolean;
  createQuote: () => Promise<void>;
} => {
  const storedQuote = useSelector((state: RootState) => state.quote);
  const dispatch = useDispatch<
    Dispatch<UpdateQuoteAction | UpdateAssumptionsAgreementAction>
  >();
  const { isLoading, withLoadingState } = useLoadingState();

  const [petsDetails] = usePetsDetails();
  const [customerDetails] = useCustomerDetails();
  const [policyDetails] = usePolicyDetails();
  const [referralCodes] = useReferralCodes();

  const disableDateChecks = useDisableDateChecks();

  const createQuote = async (): Promise<void> => {
    const requote = async (previousQuote: Quote): Promise<Quote> => {
      const requoteRequest = mapFormToRequote(
        petsDetails,
        customerDetails,
        policyDetails,
        referralCodes,
        disableDateChecks,
        previousQuote
      );

      const quote = await quoteClient.requote(requoteRequest);

      if (!shouldNotInvalidateAssumptions) {
        dispatch({
          type: UPDATE_ASSUMPTIONS_AGREEMENT,
          update: { assumptionsInvalidDueToRequote: true },
        });
      }
      return quote;
    };

    const applyQuote = async (): Promise<Quote> => {
      const quoteRequest = mapFormToQuote(
        petsDetails,
        customerDetails,
        policyDetails,
        referralCodes,
        disableDateChecks
      );

      const recaptchaSkipKey = retrieveData(RECAPTCHA_SKIP_KEY_SESSION_KEY);

      if (recaptchaSkipKey) {
        quoteRequest.recaptchaSkipKey = recaptchaSkipKey;
      } else {
        quoteRequest.recaptchaToken = await withLoadingState(getRecaptchaToken);
      }
      return quoteClient.createQuote(quoteRequest);
    };

    const quote = await withLoadingState(
      storedQuote ? () => requote(storedQuote) : applyQuote
    );

    if (quote.recaptcha) {
      trackReCaptchaScore(
        quote.recaptcha.recaptchaStatus,
        quote.recaptcha.recaptchaScore
      );
    }

    quote.petInfos = quote.petInfos.map((pet, i) => {
      return {
        ...pet,
        twentyPercentExcessConfirmation: storedQuote
          ? storedQuote.petInfos[i]?.twentyPercentExcessConfirmation
          : {
              hasAgreed: undefined,
            },
      };
    });

    dispatch({ type: UPDATE_QUOTE, quote });
  };

  return {
    isLoading,
    createQuote,
  };
};

export default useGenerateQuote;

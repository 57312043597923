import {
  addYearsToDate,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import { formatLongDateWithOrdinalSuffix } from 'helpers/dateHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsCoverDatesSectionProps = {
  csPetCheckYourDetailsDetailsSection: {
    your_cover_dates: {
      your_cover_dates_heading: string;
      policy_starts: string;
      policy_renewal_date: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      your_cover_dates {
        policy_starts
        policy_renewal_date
      }
    }
  }
`;

const useCoverDatesSection = (): Detail[] => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      your_cover_dates: { policy_starts, policy_renewal_date },
    },
  } = useStaticQuery<CsCoverDatesSectionProps>(query);
  const quote = useCurrentQuote();
  const policyInfo = quote?.policyInfo ?? undefined;
  if (policyInfo === undefined) {
    return [];
  }

  const policyStartDate = parseDate(policyInfo.coverStartDate);
  const policyRenewalDate = addYearsToDate(policyStartDate, 1);

  return [
    createDetail(policy_starts, formatLongDateWithOrdinalSuffix(policyStartDate)),
    createDetail(policy_renewal_date, formatLongDateWithOrdinalSuffix(policyRenewalDate)),
  ];
};

export default useCoverDatesSection;

import EditButton from '@rsa-digital/evo-shared-components/components/EditButton';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const UnorderedListWithoutBullets = styled.ul`
  text-align: left;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const Heading = styled.h2`
  ${fonts.headingMedium};
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(2)} 0 ${spacing(3)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(3)} 0 ${spacing(4)};
  `}
`;

export const ListItemWithMargins = styled.li`
  padding: ${spacing(2)} 0;

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(3)} 0;
  `}

  border-top: solid 1px ${colors.neutral04};
  
  :last-child {
    border-bottom: solid 1px ${colors.neutral04};
  }
`;

export const ParagraphLargeSpan = styled.span`
  ${fonts.paragraphLarge};
  & {
    font-weight: normal;
  }
`;

export const DetailLabel = styled(ParagraphLargeSpan)`
  display: block;

  ${mediaQuery.tabletPortrait`
    display: inline;
  `}
`;

export const BoldParagraphLargeSpan = styled.span`
  ${fonts.paragraphLarge};
  & {
    font-weight: bold;
  }
`;

export const EditButtonWithMargins = styled(EditButton)`
  max-height: ${spacing(6)};
  margin: auto 0;
`;

export const SpaceBetweenGrid = styled(Grid)`
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0;
  width: 100%;
`;

export const PositionedQuoteReferenceWrapper = styled.div`
  position: relative;
  width: 100%;

  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    top: ${spacing(6)};
    margin-bottom: 0;
    text-align: right;
  `}
`;

export const IconWithMargin = styled(Icon)`
  margin-right: ${spacing(3)};
`;

export const ExcessSectionDescriptionRichText = styled(RichTextWithModal)`
  margin: 0 0 ${spacing(2)} 0;
`;

export const VoluntaryExcessDisclaimerRichText = styled(RichTextWithModal)`
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(3)};
  `}
`;

export const SectionWithMarginBottom = styled.section`
  margin-bottom: ${spacing(6)};
`;

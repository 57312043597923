import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import InfoBanner from '@rsa-digital/evo-shared-components/components/InfoBanner';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import { StyledRichText } from '@rsa-digital/evo-shared-components/components/SplitInfoCard/SplitInfoCardContent/styles';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isQuoteOptionSelectionValid } from 'apiHelpers/quote/bundleCoverMapping';
import useGenerateQuote from 'apiHelpers/quote/useGenerateQuote';
import { graphql, navigate } from 'gatsby';
import React, { useState } from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import AggregatorAssumptionsSection from 'components/CheckYourDetails/AggregatorAssumptionsSection';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import useAggregatorAssumptionsRules from 'components/CheckYourDetails/AggregatorAssumptionsSection/validation';
import DeclarationSection from 'components/CheckYourDetails/DeclarationSection';
import DetailsSection from 'components/CheckYourDetails/DetailsSection';
import useQuoteReference from 'components/CheckYourDetails/DetailsSection/useQuoteReference';
import { useCheckYourDetailsPdf } from 'components/CheckYourDetailsPdf/useCheckYourDetailsPdf';
import FormFooter from 'components/FormFooter';
import HeroBanner from 'components/Header/HeroBanner';
import Layout from 'components/Layout';
import PricingOverviewPanel from 'components/PricingOverviewPanel';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import useContactDetailsRules from 'forms/CheckContactDetailsForm/validation';
import KeepingYouInformedForm from 'forms/KeepingYouInformedForm';
import useKeepingYouInformedRules from 'forms/KeepingYouInformedForm/validation';
import { Container } from 'forms/SectionHeading/styles';
import { ProductId } from 'helpers/businessConstants';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { PageTitle, trackFieldError, trackTextButtonClick } from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { usePageTracking } from 'helpers/pageTracking';
import { isQuoteFromAggs } from 'helpers/productHelpers';
import { useQuoteSummaryDetailsValidity } from 'helpers/quoteAndBuyPageFlowHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import {
  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
  storeData,
} from 'helpers/sessionStorageHelpers';
import useAggregatorBrandDetails from 'helpers/useAggregatorBrandDetails';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDeclarations from 'helpers/useDeclarations';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import { initialQuoteOptions } from 'state/formData/quoteOptions';
import { CsHero } from 'types/contentStack';
import {
  ImageWithFixedHeight,
  QuoteReferenceLabel,
  QuoteReferenceValue,
  QuoteReferenceWrapper,
} from './styles';

const ECOMMERCE_STEP = 2;

type CheckYourDetailsProps = {
  data: {
    csPetCheckYourDetails: {
      meta_title: string;
      hero: CsHero;
      next_button_text: string;
      info_panel_html: string;
    };
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text: string;
        excess_spinner_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetails {
      meta_title
      hero {
        heading
        subheading
      }
      next_button_text
      info_panel_html
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
        excess_spinner_text
      }
    }
  }
`;

const CheckYourDetails: React.FC<CheckYourDetailsProps> = ({
  data: {
    csPetCheckYourDetails: { hero, next_button_text, meta_title, info_panel_html },
    csPetGlobalConfig: {
      loading_spinner: { email_spinner_text, excess_spinner_text },
    },
  },
}) => {
  const aggregatorAssumptionsSectionId = 'aggregator-assumptions-section';
  const declarationSectionId = 'declaration-section';
  const keepingYouInformedSectionId = 'keeping-you-informed-section';
  const checkYourDetailsInfoPanelId = 'check-your-details-info-panel';

  const quote = useCurrentQuote();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const isAggsQuote = isQuoteFromAggs(quote);

  const [customerDetails] = useCustomerDetails();
  const [policyDetails] = usePolicyDetails();

  const areQuoteSummaryDetailsValid = useQuoteSummaryDetailsValidity();

  if (quote.petInfos) {
    if (!areQuoteSummaryDetailsValid) {
      navigate(quoteAndBuyRoutes.quoteSummary);
    }
  }

  const contactDetailsRules = useContactDetailsRules();

  const {
    declarations,
    updateDeclarationAgreement,
    declarationRules,
  } = useDeclarations();

  const aggregatorBrandDetails = useAggregatorBrandDetails(quote.productId);

  const aggregatorAssumptionRules = useAggregatorAssumptionsRules();
  const keepingYouInformedRules = useKeepingYouInformedRules();

  const [assumptionsAgreement] = useAssumptionsAgreement();

  const rules = {
    ...contactDetailsRules,
    ...declarationRules,
    ...(isAggsQuote ? aggregatorAssumptionRules : {}),
    ...(isAggsQuote ? keepingYouInformedRules : {}),
  };

  const { getError, validateOnSubmit, showValidation } = useValidation(
    {
      ...customerDetails,
      declarations,
      ...assumptionsAgreement,
      keepingYouInformed: policyDetails.keepingYouInformed,
    },
    rules,
    trackFieldError
  );

  const assumptions = useAssumptions();

  /*  We only display the aggregator assumptions section if some
   *  assumptions exist and the assumptions have not yet been agreed
   *  to when the page is first rendered, so we must record the assumptionsAgreed value on render.
   */
  const [assumptionsAgreedOnRender] = useState(assumptionsAgreement.assumptionsAgreed);

  const {
    saveAndEmailQuote,
    savedQuoteConfirmationModal,
    isSaveInProgress,
  } = useSaveAndEmailQuote(PageTitle.CheckYourDetails);

  const { createQuote: requote, isLoading: isRequoting } = useGenerateQuote();

  const moveNext = async (): Promise<void> => {
    if (
      policyDetails.keepingYouInformed !== quote?.policyInfo?.contactable ||
      customerDetails.customerEmail !== quote.customerInfo?.email ||
      customerDetails.customerTelephone !== quote.customerInfo?.contactPhoneNumber
    ) {
      await requote();
    }
    navigate(quoteAndBuyRoutes.payment, {
      state: { detailsValid: true },
    });
  };

  const quoteReference = useQuoteReference();

  const showAssumptions = !!assumptions && !assumptionsAgreedOnRender;
  const generatePdf = useCheckYourDetailsPdf(
    quote,
    declarations,
    assumptionsAgreement,
    policyDetails.keepingYouInformed,
    showAssumptions
  );

  usePageTracking(meta_title, !!quote.customerInfo);
  useCheckoutTracking(ECOMMERCE_STEP, quote, true);

  const loadingMsgText = (): string => {
    if (isRequoting) return 'Updating preferences, please wait';
    if (isSaveInProgress) return email_spinner_text;
    return excess_spinner_text;
  };

  return (
    <LoadQuoteWrapper>
      <Layout
        currentStep={QuoteAndBuyStep.CheckDetails}
        pageTitle={PageTitle.CheckYourDetails}
        metaTitle={meta_title}
        sessionExpiryOption={
          isQuoteOptionSelectionValid(quoteOptions)
            ? SessionExpiryOption.EMAIL_QUOTE_SESSION_EXPIRY
            : undefined
        }>
        {(isRequoting || isSaveInProgress) && (
          <LoadingOverlayV2 loadingMessage={loadingMsgText()} timeDuration={10} />
        )}
        <HeroBanner heading={hero.heading} subheading={hero.subheading} />
        <QuoteReferenceWrapper>
          <QuoteReferenceLabel>{quoteReference.label} </QuoteReferenceLabel>
          <QuoteReferenceValue>{quoteReference.value}</QuoteReferenceValue>
        </QuoteReferenceWrapper>
        <Grid alignLeft>
          <GridItem desktop={10} tabletLandscape={10}>
            <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
              {quote.productId !== ProductId.DIRECT && (
                <>
                  {aggregatorBrandDetails?.logo && (
                    <Grid data-cy="aggregator-logo" alignLeft>
                      <GridItem>
                        <ImageWithFixedHeight image={aggregatorBrandDetails.logo} />
                      </GridItem>
                    </Grid>
                  )}
                  {showAssumptions && (
                    <section aria-labelledby={aggregatorAssumptionsSectionId}>
                      <AggregatorAssumptionsSection
                        sectionHeadingId={aggregatorAssumptionsSectionId}
                        getError={getError}
                        showValidation={showValidation}
                      />
                    </section>
                  )}
                </>
              )}
              <DetailsSection
                currentQuote={quote}
                getError={getError}
                showValidation={showValidation}
              />
              <PricingOverviewPanel
                pageTitle={PageTitle.CheckYourDetails}
                displayQuoteSummaryDiscounts={false}
              />
              <section aria-labelledby={declarationSectionId}>
                <DeclarationSection
                  sectionHeadingId={declarationSectionId}
                  declarations={declarations}
                  updateHasAgreed={(update: boolean, index: number) => {
                    updateDeclarationAgreement(update, index);
                    showValidation('declarations', ['hasAgreed', index]);
                  }}
                  getError={getError}
                />
              </section>
              {isAggsQuote && (
                <section aria-labelledby={keepingYouInformedSectionId}>
                  <KeepingYouInformedForm
                    sectionHeadingId={keepingYouInformedSectionId}
                    formValidation={{ getError, showValidation }}
                    pageTitle={PageTitle.CheckYourDetails}
                  />
                </section>
              )}
              {info_panel_html && (
                <section>
                  <Container>
                    <FlexibleWidthDivider />
                    <InfoBanner id={checkYourDetailsInfoPanelId}>
                      <StyledRichText html={info_panel_html} />
                    </InfoBanner>
                  </Container>
                </section>
              )}
              <FormFooter
                contentColumns={{ desktop: 10, tabletLandscape: 10 }}
                backButton={{
                  onClick: () => {
                    trackTextButtonClick(PageTitle.CheckYourDetails, 'Back');
                    navigate(quoteAndBuyRoutes.quoteSummary);
                  },
                }}
                moveNextButton={{
                  text: next_button_text,
                  onClick: () => {
                    storeData(
                      PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
                      quote.quoteOptions.isAnnualPayment ? 'ANNUAL' : 'MONTHLY'
                    );
                    trackTextButtonClick(PageTitle.CheckYourDetails, 'Continue');
                  },
                }}
                downloadButton={{
                  onClick: generatePdf,
                }}
                saveButton={{
                  onClick: () => {
                    if (isQuoteOptionSelectionValid(quoteOptions)) {
                      saveAndEmailQuote();
                    }
                  },
                }}
                pageTitle={PageTitle.CheckYourDetails}
              />
              {savedQuoteConfirmationModal}
            </form>
          </GridItem>
        </Grid>
      </Layout>
    </LoadQuoteWrapper>
  );
};

export default CheckYourDetails;

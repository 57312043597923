import {
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { AssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import useAssumptions from './assumptions';

type AggregatorAssumptionsErrorMessages = {
  csPetAggregators: {
    assumptions: {
      error_messages: {
        incorrect_assumptions: string;
        missing: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAggregators {
      assumptions {
        error_messages {
          incorrect_assumptions
          missing
        }
      }
    }
  }
`;

const useAggregatorAssumptionsRules = (): ValidationRules<AssumptionsAgreement> => {
  const csAggregatorAssumptions = useStaticQuery<AggregatorAssumptionsErrorMessages>(
    query
  ).csPetAggregators.assumptions;
  const assumptions = useAssumptions();
  return {
    assumptionsAgreed: validateIf(
      (data) => !data.assumptionsInvalidDueToRequote && !!assumptions,
      [
        required(csAggregatorAssumptions.error_messages.missing),
        {
          validityCondition: (value) => value !== false,
          errorMessage: csAggregatorAssumptions.error_messages.incorrect_assumptions,
        },
      ]
    ),
  };
};

export default useAggregatorAssumptionsRules;

import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment, MouseEventHandler } from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { CsIcon } from 'types/contentStack';
import {
  BoldParagraphLargeSpan,
  DetailLabel,
  EditButtonWithMargins,
  ExcessSectionDescriptionRichText,
  Heading,
  IconWithMargin,
  ListItemWithMargins,
  ParagraphLargeSpan,
  PositionedQuoteReferenceWrapper,
  SectionWithMarginBottom,
  SpaceBetweenGrid,
  UnorderedListWithoutBullets,
  VoluntaryExcessDisclaimerRichText,
} from './styles';
import { AddOnDetail } from '../useAddOnSection';

type DetailGroupSectionProps = {
  quoteReference?: Detail;
  sectionHeading: string;
  sectionId: string;
  details: Detail[];
  addOnDetails?: AddOnDetail[];
  isExcessSection?: boolean;
  editButtonLabel: string;
  editButtonScreenreaderText: string;
  editButtonOnClick: MouseEventHandler;
  piiOptions?: PiiOptions;
  isPetAgeOverNine?: boolean;
};

export type Detail = {
  label: string;
  value: string;
  childAfter?: React.ReactNode;
};

export type PiiOptions = {
  headingIsPii?: boolean;
  detailLabelsArePii?: boolean;
  detailValuesArePii?: boolean;
};

type AddOnDetailsCsProps = {
  csPetCheckYourDetailsDetailsSection: {
    your_cover_level: {
      add_on_details: {
        included_icon: [CsIcon];
        not_included_icon: [CsIcon];
        included_text: string;
        not_included_text: string;
      };
    };
    excess: {
      excess_section_description: string;
      voluntary_excess_disclaimer: string;
      voluntary_excess_disclaimer_pet_over_nine: string;
    };
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      your_cover_level {
        add_on_details {
          included_icon {
            icon_code
          }
          not_included_icon {
            icon_code
          }
          included_text
          not_included_text
        }
      }
      excess {
        excess_section_description
        voluntary_excess_disclaimer
        voluntary_excess_disclaimer_pet_over_nine
      }
    }
  }
`;

const DetailGroupSection: React.FC<DetailGroupSectionProps> = ({
  details,
  addOnDetails,
  isExcessSection = false,
  editButtonOnClick,
  editButtonLabel,
  editButtonScreenreaderText,
  quoteReference,
  sectionHeading,
  sectionId,
  piiOptions,
  isPetAgeOverNine,
}) => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      your_cover_level: {
        add_on_details: {
          included_icon,
          not_included_icon,
          included_text,
          not_included_text,
        },
      },
      excess: {
        excess_section_description,
        voluntary_excess_disclaimer,
        voluntary_excess_disclaimer_pet_over_nine,
      },
    },
  } = useStaticQuery<AddOnDetailsCsProps>(query);

  const includedIcon = unwrapSingleton(included_icon)?.icon_code;
  const notIncludedIcon = unwrapSingleton(not_included_icon)?.icon_code;

  return (
    <SectionWithMarginBottom aria-labelledby={sectionId}>
      {quoteReference && (
        <PositionedQuoteReferenceWrapper>
          <ParagraphLargeSpan>{quoteReference.label} </ParagraphLargeSpan>
          <BoldParagraphLargeSpan>{quoteReference.value}</BoldParagraphLargeSpan>
        </PositionedQuoteReferenceWrapper>
      )}
      <SpaceBetweenGrid>
        <Heading id={sectionId} data-pii-mask={piiOptions?.headingIsPii}>
          {sectionHeading}
        </Heading>
        <EditButtonWithMargins
          id={`${sectionId}-edit-button`}
          screenReaderText={editButtonScreenreaderText}
          onClick={editButtonOnClick}>
          {editButtonLabel}
        </EditButtonWithMargins>
      </SpaceBetweenGrid>
      {isExcessSection && (
        <ExcessSectionDescriptionRichText
          html={excess_section_description}
          pageTitle={PageTitle.CheckYourDetails}
        />
      )}
      <UnorderedListWithoutBullets>
        {details.map((detail) => (
          <Fragment key={detail.label}>
            <ListItemWithMargins>
              <DetailLabel data-pii-mask={piiOptions?.detailLabelsArePii}>
                {detail.label}{' '}
              </DetailLabel>
              <BoldParagraphLargeSpan data-pii-mask={piiOptions?.detailValuesArePii}>
                {detail.value}
              </BoldParagraphLargeSpan>
              {detail?.childAfter}
            </ListItemWithMargins>
          </Fragment>
        ))}
      </UnorderedListWithoutBullets>
      {isExcessSection && (
        <VoluntaryExcessDisclaimerRichText
          html={
            isPetAgeOverNine
              ? voluntary_excess_disclaimer_pet_over_nine
              : voluntary_excess_disclaimer
          }
          pageTitle={PageTitle.CheckYourDetails}
        />
      )}
      {addOnDetails?.map((detail) => (
        <UnorderedListWithoutBullets key={detail.addOnLabel}>
          <ListItemWithMargins>
            {includedIcon && detail.isIncluded && (
              <IconWithMargin
                name={includedIcon}
                aria-label="included"
                size="small"
                color={colors.core01}
              />
            )}
            {notIncludedIcon && !detail.isIncluded && (
              <IconWithMargin
                name={notIncludedIcon}
                aria-label="not included"
                size="small"
                color={colors.notificationError}
              />
            )}
            <DetailLabel>{detail.addOnLabel} </DetailLabel>
            <BoldParagraphLargeSpan>
              {detail.isIncluded ? included_text : not_included_text}
            </BoldParagraphLargeSpan>
          </ListItemWithMargins>
        </UnorderedListWithoutBullets>
      ))}
    </SectionWithMarginBottom>
  );
};

export default DetailGroupSection;

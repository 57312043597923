import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { PolicyDetails } from 'state/formData/policyDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsKeepingYouInformedErrorMessages = {
  csPetAboutYourPolicyQuestions: {
    keeping_you_informed: CsErrorsMissingOnly;
  };
};

const query = graphql`
  query {
    csPetAboutYourPolicyQuestions {
      keeping_you_informed {
        error_messages {
          missing
        }
      }
    }
  }
`;

const useKeepingYouInformedRules = (): ValidationRules<
  Pick<PolicyDetails, 'keepingYouInformed'>
> => {
  const errorMessages = useStaticQuery<CsKeepingYouInformedErrorMessages>(query);

  return {
    keepingYouInformed: [
      required(
        errorMessages.csPetAboutYourPolicyQuestions.keeping_you_informed.error_messages
          .missing
      ),
    ],
  };
};

export default useKeepingYouInformedRules;

type actionArg = {
  action: string;
};

// window.grecaptcha is created by the grecaptcha script
// loaded in <head>
declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (key: string, action: actionArg) => PromiseLike<string>;
    };
  }
}

const getRecaptchaToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(async () => {
      const key = process.env.GATSBY_RECAPTCHA_SITE_KEY;
      // GATSBY_RECAPTCHA_SITE_KEY is checked during build (gatsby-config.js)
      // It shouldn't be undefined, but double-check anyway.
      /* istanbul ignore if */
      if (!key) {
        reject(new Error('reCAPTCHA key not found'));
      } else {
        try {
          const token = await window.grecaptcha.execute(key, { action: 'submit' });
          resolve(token);
        } catch (err) {
          reject(err);
        }
      }
    });
  });
};

export default getRecaptchaToken;

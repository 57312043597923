import { WarningPanel } from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { Heading } from 'forms/SectionHeading/styles';

export const ContactDetailsSectionContainer = styled.section`
  margin-top: ${spacing(8)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;

export const StyledHeading = styled(Heading)`
  border-bottom: 1px solid ${colors.neutral04};
  margin-bottom: ${spacing(2)};
  padding-bottom: ${spacing(4)};
`;

export const StyledWarningPanel = styled(WarningPanel)<{ children?: React.ReactNode }>`
  margin-bottom: ${spacing(4)};

  ${RichText} {
    ${fonts.paragraphSmall};
  }
`;
